<template>
  <div class="not-found-wrapper">
    <h2>404</h2>
    <h5>Sorry, something went wrong. We can not seem to find the page you were looking for.</h5>
    <router-link class="btn btn-primary btn-primary mr-2 mt-4" :to="{ name: 'landing' }">
      <span>Back To Home Page</span>
    </router-link>
  </div>
</template>
  
<script>
export default {
  name: 'not-found',

  data() {
    return {
    }
  },

  mounted() {
  },

  methods: {
  },
}
</script>
  
<style lang="scss" >
.not-found-wrapper {
  padding: 20px 40px;
  h5 {
    line-height: 1.4em;
  }
}
</style>
  